import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgAddNewData, SvgChekedReturn, SvgClose, SvgDelete, SvgImg, SvgPdf, SvgReturn, SvgSave, SvgSetaRight, SvgVimeo, SvgYoutube } from "components/SvgFile";
import { Reg_Students } from "services/RegisterData";
import { cepMask, cpfMask, phoneMask } from "services/Mask";

export default function Page_Students_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    
    const [ entry, setEntry ]                = useState(GetDataPage('entry'));
    const [ dataPage, setDataPage ]          = useState(GetDataPage('students'));
    const [ showTeamList , setShowTeamList ] = useState(GetDataPage('team'));
    const [ showCourses, setShowCourses ]    = useState(GetDataPage('courses'));
    const [ idPage, setIdPage ]              = useState(GetListPag('currentPageId'));

    const [ name, setName ]         = useState(InitialData('name'));
    const [ login, setLogin ]       = useState(InitialData('login'));
    const [ email, setEmail ]       = useState(InitialData('email'));
    const [ phone, setPhone ]       = useState(InitialData('phone'));
    const [ file, setFile ]         = useState(InitialData('file'));
    const [ state, setState ]       = useState(false);
    const [ country, setCountry ]   = useState(InitialData('country'));
    const [ address, setAddress ]   = useState(InitialData('address'));
    const [ cep, setCep ]           = useState(InitialData('cep'));
    const [ city, setCity ]         = useState(InitialData('city'));
    const [ uf, setUf ]             = useState(InitialData('uf'));
    const [ cpf, setCpf ]           = useState(InitialData('cpf'));
    const [ note, setNote ]         = useState(InitialData('note'));
    const [ pass, setPass ]         = useState('');
    const [ nivel, setNivel ]       = useState(InitialData('nivel'));

    const [ listTeam, setListTeam ]               = useState(InitialData('list_team'));
    const [ listNewTeam, setListNewTeam ]         = useState([]);

    const [ coursesReleased, setCoursesReleased ] = useState(InitialData('courses_released'));
    const [ stateNewCourse, setStateNewCourse ]   = useState(false);
    const [ addCourse, setAddCourse ]             = useState({ "value": 0, "label" : "Selecione um curso..."});
    const [ typeAdd, setTypeAdd ]                 = useState(1);
    const [ addClasses, setAddClasses ]           = useState([]);

    const [ recording, setRecording ] = useState(InitialData('recording'));

    const [ listPayment, setListPayment ] = useState(ShowPayment())

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('entry', setEntry);
        RegisterDataPage('students', setDataPage);
        RegisterDataPage('team', setShowTeamList);
        RegisterDataPage('courses', setShowCourses);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('students'));
        setEntry(GetDataPage('entry'));
        setShowTeamList(GetDataPage('team'));
        setShowCourses(GetDataPage('courses'));
        setIdPage(GetListPag('currentPageId'));
        setListPayment(ShowPayment());

        setName(InitialData('name'));
        setLogin(InitialData('login'));
        setEmail(InitialData('email'));
        setPhone(InitialData('phone'));
        setFile(InitialData('file'));
        setCountry(InitialData('country'));
        setAddress(InitialData('address'));
        setCep(InitialData('cep'));
        setCity(InitialData('city'));
        setUf(InitialData('uf'));
        setCpf(InitialData('cpf'));
        setNote(InitialData('note'));
        setNivel(InitialData('nivel'));
        setListTeam(InitialData('list_team'));
        setCoursesReleased(InitialData('courses_released'));
        setRecording(InitialData('recording'));
        
        setState(false);
        setPass('');
        setListNewTeam([]);
        setStateNewCourse(false);
        setTypeAdd(0);
        setAddClasses([]);
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('students'));
        setEntry(GetDataPage('entry'));
        setShowTeamList(GetDataPage('team'));
        setShowCourses(GetDataPage('courses'));
        setIdPage(GetListPag('currentPageId'));
        setListPayment(ShowPayment());

        setName(InitialData('name'));
        setLogin(InitialData('login'));
        setEmail(InitialData('email'));
        setPhone(InitialData('phone'));
        setFile(InitialData('file'));
        setCountry(InitialData('country'));
        setAddress(InitialData('address'));
        setCep(InitialData('cep'));
        setCity(InitialData('city'));
        setUf(InitialData('uf'));
        setCpf(InitialData('cpf'));
        setNote(InitialData('note'));
        setNivel(InitialData('nivel'));
        setListTeam(InitialData('list_team'));
        setCoursesReleased(InitialData('courses_released'));   
        setRecording(InitialData('recording')); 

        setState(false);
        setPass('');
        setListNewTeam([]);
        setStateNewCourse(false);
        setTypeAdd(0);
        setAddClasses([]);
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'nivel'){
                return { "value": newData[type], "label": newData['nivel_page'] };
            }
            return newData[type];
        }
        if(type == 'list_team' || type == 'courses_released' || type == 'recording'){
            return [];
        }
        if(type == 'nivel'){
            return '0';
        }
        return '';
    }

    function ShowPayment(){
        const newData = entry.filter(item => item.id_login == idPage);
        return newData;
    }

    function ShowListCourses(){
        const options = []
        showCourses.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.title
            });
        });
        return options;
    }

    function ListCourseClasses(){
        const newData = [];
        Object.keys(showCourses).map((key, index)=>{
            if(showCourses[key].id == addCourse.value){
                Object.keys(showCourses[key].list_classes).map((key_, index_)=>{
                    newData.push({ "value": showCourses[key].list_classes[key_].id, "label": showCourses[key].list_classes[key_].title });
                });

            }
        });
        return(
            <div className="div_input space_div courses">
                <Select className="select_list_data space_div" defaultOptions options={ newData } onChange={ (e)=> { setAddClasses(e) } } value={ addClasses } isMulti />
            </div>
        )
    }

    function NewTeam(){
        setListTeam([...listTeam, { "id": 0, "idNew": 0 }]);
    }

    function NewRecording(){
        setRecording([...recording, { "id": 0, "type": "Youtube", "title": "", "video": "" }]);
    }

    function HandleRecording(type, index, value){
        const newData = [...recording];
        newData[index][type] = value;
        setRecording(newData);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'students');
        SetListPag('currentPageId', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setState(true);
        }else {
            setState(false);
        }
    }

    function DeleteData(type, index, id, value){
        switch (type) {
            case 'delete_team':
                    if(id == 0){
                        const newData = [...listTeam];
                        newData.splice(index, 1);
                        setListTeam(newData);
                    }else {
                        SetModalData('Confirmation', { "origin": "students", "type" : type, "id" : id, "name": value });
                        SetModalState('Confirmation', true);
                    }
                break;
            
            case 'delete_classe': case 'delete_course': case 'delete_payment': case 'delete_recording':
                    SetModalData('Confirmation', { "origin": "students", "type" : type, "id" : id, "name": value });
                    SetModalState('Confirmation', true);
                break;
        }
    }

    function HandleTeam(index, value){
        const newData = [...listTeam];
        newData[index]['idNew'] = value;
        setListTeam(newData);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        let infCourse = 0;
        if(Object.keys(addCourse).length > 0){
            infCourse = addCourse.value;
        }
        Reg_Students(idPage, userId, name, login, email, phone, file, country, address, cep, city, uf, cpf, note, pass, nivel, recording, listTeam, infCourse, typeAdd, addClasses, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }    

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Page_Students_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados do aluno</div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <Select className="access" defaultOptions options={[{ "value": 0, "label": "Free" }, { "value": 1, "label": "Bronze" }, { "value": 2, "label": "Prata" }, { "value": 3, "label": "Ouro" }]} onChange={ (e)=> { setNivel(e); } } value={ nivel } placeholder="..." required />
                            <span className="name_input">Tipo</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="data_input" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="cpf" onChange={ (e)=>{ setCpf(e.target.value); } } value={ cpfMask(cpf) } maxLength="14" />
                            <span className="name_input">CPF</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="login" onChange={ (e)=>{ setLogin(e.target.value) } } value={ login } maxLength="20" required />
                            <span className="name_input">Login</span>
                        </div>
                        <div className="div_input">
                            <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" />
                            <span className="name_input">Senha</span>
                        </div>
                        <div className="div_show_img">
                            <div className={ state == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { state == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ setFile(''); setState(false); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        state == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#FFFFFF" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                            <SvgImg color="#FFFFFF" className="icons"/>
                                        </div>
                                    }
                                </> : null
                            }  
                        </div>
                        <div className="div_input">
                            <input type="email" className="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="140" required />
                            <span className="name_input">E-mail</span>
                        </div> 
                        <div className="div_input">
                            <input type="text" className="phone" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phoneMask(phone) } />
                            <span className="name_input">Telefone</span>
                        </div> 
                        <div className="div_input">
                            <input type="text" className="country" onChange={ (e)=>{ setCountry(e.target.value) } } value={ country } />
                            <span className="name_input">País</span>
                        </div> 
                        <div className="div_input">
                            <input type="text" className="data_input" onChange={ (e)=>{ setAddress(e.target.value) } } value={ address } />
                            <span className="name_input">Endereço</span>
                        </div> 
                        <div className="div_input">
                            <input type="text" className="city" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } />
                            <span className="name_input">Cidade</span>
                        </div> 
                        <div className="div_input">
                            <input type="text" className="uf" onChange={ (e)=>{ setUf(e.target.value) } } value={ uf } />
                            <span className="name_input" maxLength="14">UF</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="cep" onChange={ (e)=>{ setCep(e.target.value) } } value={ cepMask(cep) } />
                            <span className="name_input" maxLength="14">CEP</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ note != '' ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                            <span className="name_input">Observação</span>
                        </div>
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Gravações do professor</div>
                        <div className="cursor_pointer" onClick={ ()=>{ NewRecording() } }>
                            + gravação
                        </div>
                    </div>
                    <div className="list_input_data">
                        {
                            recording.length > 0 ?
                                recording.map((key, index)=>{
                                    return(
                                        <div className="div_recording" key={ index }>
                                            <div className="div_input">
                                                <Select className="access" defaultOptions options={[{ "value": "Vimeo", "label": "Vimeo" }, { "value": "Youtube", "label": "Youtube" }]} onChange={ (e)=> { HandleRecording('type', index, e); } } value={ { "value": key.type, "label": key.type } } placeholder="..." required />
                                                <span className="name_input">Tipo</span>
                                            </div>
                                            <div className="div_input space_div">
                                                <input type="text" className="data_input" onChange={ (e)=>{ HandleRecording('title', index, e.target.value) } } value={ key.title } maxLength="140" />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="text" className="data_input input_code" onChange={ (e)=>{ HandleRecording('video', index, e.target.value) } } value={ key.video } maxLength="18" required />
                                                <span className="name_input">Vídeo</span>
                                            </div>

                                            <div className="div_input" onClick={ ()=>{ OpenFile(key.type, key.video) } }>
                                                {
                                                    key.video !='' ?
                                                        key.type == 'Vimeo' ?
                                                        <SvgVimeo color="#FFFFFF" className="icons"/> :
                                                        <SvgYoutube color="#FFFFFF" className="icons"/>
                                                    : ""
                                                }
                                            </div>

                                            <div className="div_input" onClick={ ()=>{ DeleteData('delete_recording', index, key.id, key.name) } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    )
                                })
                            :
                            <div className="no_data">Nenhuma gravação adicionada até o momento...</div>
                        }
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Turmas</div>
                        <div className="cursor_pointer" onClick={ ()=>{ NewTeam() } }>
                            + turma
                        </div>
                    </div>
                    <div className="list_input_data">
                        {
                            listTeam.length > 0 ?
                                listTeam.map((key, index)=>{
                                    return(
                                        <div className="page_released" key={ index }>
                                            {
                                                key.id == 0 ?     
                                                <div className="name_team">
                                                    <select onChange={ (e)=>{ HandleTeam(index, e.target.value); } }>
                                                        <option value="">#</option>
                                                        {
                                                            showTeamList.map((key_1, index_1)=>{
                                                                return(
                                                                    <option value={ key_1.id } key={ index_1 }>{ key_1.name }</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                :
                                                <div className="name_team" style={ { padding: "0px 10px" } }>{ key.name }</div>
                                            }
                                            <div className="" onClick={ ()=>{ DeleteData('delete_team', index, key.id, key.name) } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    )
                                })
                            : 
                            <div className="no_data">Nenhuma turma adicionada até o momento...</div>
                        }  
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Cursos liberados</div>
                        <div className="cursor_pointer" onClick={ ()=>{ setStateNewCourse(!stateNewCourse); } }>
                            { stateNewCourse != true ? "+ curso" : "- cancelar" }
                        </div>
                    </div>
                    {
                        stateNewCourse != true ? null : 
                        <div className="div_input add_new_course">
                            <div className="div_input space_div courses">                            
                                <Select className="space_div" defaultOptions options={ ShowListCourses() } onChange={ (e)=> { setAddCourse(e); setAddClasses([]); } } value={ addCourse } /> 
                            </div>
                            {/* <div className="div_input courses">  
                                <select onChange={ (e)=>{ setTypeAdd(e.target.value) } } style={ { padding: 8 } } value={ typeAdd }>
                                    <option value="0">Todas aulas</option>
                                    <option value="1">Escolher aulas</option>
                                </select>
                            </div>
                            {
                                typeAdd == 1 ? ListCourseClasses(addCourse.value) : ""
                            } */}
                            {
                                ListCourseClasses(addCourse.value)
                            }
                        </div>
                    }
                    <div className="list_input_data">
                        {
                            coursesReleased.length > 0 ?
                                <div className="show_courses">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <th width="300">Curso</th>
                                                <th>Aulas</th>
                                                <th width="20" align="right"></th>
                                            </tr>
                                        </tbody>
                                        {
                                            coursesReleased.map((key, index)=>{
                                                return(
                                                    <tbody key={ index }>
                                                        <tr>
                                                            <td rowSpan={ key.list_classes.length + 1 }>
                                                                { key.nameCourse }
                                                            </td>
                                                        </tr>
                                                        {
                                                            key.list_classes.map((key_1, index_1)=>{
                                                                return(
                                                                    <tr key={ index_1 }>
                                                                        <td className="table_border_left">
                                                                            { key_1.nameClasse }
                                                                        </td>
                                                                        <td align="right">
                                                                            <div className="" onClick={ ()=>{ DeleteData('delete_classe', index_1, key_1.id, key_1.nameClasse) } }>
                                                                                <SvgDelete color="#f00000" className="icons" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                );
                                            })
                                        }
                                    </table>
                                </div>
                            :
                            stateNewCourse != true ? <div className="no_data">Nenhum curso liberado até o momento...</div> : null
                        }  
                    </div>
                </div>

                {/* 
                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Aulas assistida</div>
                    </div>
                    <div className="list_input_data">
                    </div>
                </div> 
                */}

                {
                    listPayment.length > 0 ?
                    <div className="show_page_data">
                        <div className="type_title">
                            <div className="">Comprovantes de pagamento</div>
                        </div>
                        <div className="list_input_data">
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <th width="20" align="left">#</th>
                                        <th>Título</th>
                                        <th width="80">Valor</th>
                                        <th width="90" align="center">Data pagamento</th>
                                        <th width="20" align="right">#</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        listPayment.map((key, index)=>{
                                            let ext = '';
                                            if(key.file !=''){
                                                let show_ext = key.file.split('.');
                                                ext = show_ext[1];
                                            }
                                            return(
                                                <tr key={ index }>
                                                    <td align="left">{ index + 1 }</td>
                                                    <td>{ key.title }</td>
                                                    <td>{ key.cash }</td>
                                                    <td>{ key.payment_br }</td>
                                                    <td>
                                                        <div className="div_opt_alt">
                                                            {
                                                                key.status == 1 ? <SvgChekedReturn className="icons" color="#5D9200" /> : 
                                                                    key.status == 2 ? <SvgClose className="icons" color="#f00000" /> : null
                                                            }
                                                            {
                                                                key.file !='' ?
                                                                    <>
                                                                        {
                                                                            ext == "pdf" ?
                                                                            <div onClick={ ()=>{ OpenFile('showPdf', 'financial/entry/' + key.file) } }>
                                                                                <SvgPdf color="#324d6b" className="icons" />
                                                                            </div> :
                                                                            <div onClick={ ()=>{ OpenFile('showImg', 'financial/entry/' + key.file) } }>
                                                                                <SvgImg color="#324d6b" className="icons" />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                : ""
                                                            }
                                                            <div className="" onClick={ ()=>{ DeleteData('delete_payment', index, key.id, key.title) } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : null
                }
            </form>
        </div>
    )
}