import React, { useState, useEffect } from "react";

import './StatusTeam.css';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SvgClose, SvgDownload } from "components/SvgFile";

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { Reg_TeamStatusClasses } from "services/RegisterData";

export default function PopUP_StatusTeam(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ listFile, setListFile ]         = useState([]);
    const [ statusClasse, setStatusClasse ] = useState('');
    const [ textClasse, setTextClasse ]     = useState('');

    useEffect(()=>{
        RegisterModalData('StatusTeam', setModaldata);
        RegisterModalObserver('StatusTeam', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setListFile(modalData.file_classe);
            setStatusClasse(modalData.status_classe);
            setTextClasse(modalData.text_classe);
        }
    }, [showPopUp]);

    function ClosePopUp(){
        SetModalState('StatusTeam', false);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function RegisterNewData(){
        Reg_TeamStatusClasses(modalData.id_page, modalData.id_user, modalData.id, statusClasse, textClasse, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        SetModalState('StatusTeam', false);
    }

    function TypeText(value){
        console.log(value);
        switch (value) {
            case "1":
                    setTextClasse(`Aprovado com sucesso, Agradecemos a participação`);
                break;
            case "2":
                    setTextClasse(`Aprovado com sucesso. Agradecemos a participação`);
                break;
            case "3":
                    setTextClasse(`Infelizmente será necessário realizar a tarefa novamente.`);
                break;
        }
    }

    function CallbackSuccess(){
    }

    function CallbackError(){
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all StatusTeam">
                        <div className="div_data">
                            <div className="title">
                                Alterar dados
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select onChange={ (e)=>{ setStatusClasse(e.target.value); TypeText(e.target.value) } } value={ statusClasse } multiple={ false }>
                                            <option value="0">#</option>
                                            <option value="1">Regular</option>
                                            <option value="2">Aprovado</option>
                                            <option value="3">Reprovado</option>
                                        </select>
                                        <span className="name_input">Status</span>
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <JoditEditor className="show_textarea" config={ config } value={ textClasse ? textClasse.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTextClasse(newContent) } />
                                        <span className="name_input">Observação</span>
                                    </div>
                                </div>
                                {
                                    listFile.length > 0 ?
                                    <>
                                        <div className="list_inf_file" style={ { marginTop: '20px' } }>
                                            <div className="file_name">Nome</div>
                                            <div className="file_data">Data</div>
                                            <div className="file_download">#</div>
                                        </div>
                                        {
                                            listFile.map((key, index)=>{
                                                return(
                                                    <div className="list_inf_file" key={ index }>
                                                        <div className="file_name">{ key.title }</div>
                                                        <div className="file_data">{ key.date }</div>
                                                        <div className="file_download">
                                                            <a href={ "./assets/login/" + key.file } download={ "./assets/login/" + key.file }>
                                                                <SvgDownload className="icons" color="#FFFFFF"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    : <div className="">Nenhum arquivo adicionado</div>
                                }
                                <div className="register_data save_popup" onClick={ ()=>{ RegisterNewData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
