import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { Reg_Alt_Highlight, Reg_DeleteData, Reg_UpdateJson } from "services/RegisterData";

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { GetListPag, RegisterListPag } from "interface/Page";

export default function PopUP_Confirmation(){

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);

        RegisterListPag('currentPage', setCurrentPage);
    }, []);

    function TypeAlt(){
        let highlight = 0;
        if(modalData.highlight == 0){
            highlight = 1;
        }
        switch(modalData.type){
            case "update_json":
                    // Reg_UpdateJson(modalData.type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;

            case "alt_highlight":
                    // Reg_Alt_Highlight(currentPage, modalData.type, modalData.id, highlight, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() })
                break;

            default:
                    Reg_DeleteData(currentPage, modalData.type, modalData.id, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;

        }
        SetModalState('Confirmation', false);
    }

    function CallbackSuccess(){
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "update_json":
                return "Atualizar plataforma";

            case "alt_highlight":
                return (
                    <div>
                        {
                            modalData.highlight == 0 ? "Destacar o curso" : "Remover o destaque do curso"
                        }
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "disabled_access_dash":
                return (
                    <div>
                        Desabilitar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "reactivate_access_dash":
                return (
                    <div>
                        Reabilitar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "courses_hide":
                return (
                    <div>
                        Ocultar curso:
                        <div>{ modalData.name }</div>
                    </div>
                );

            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all Confirmation">
                        <div className="div_data">
                            <div className="title title_confirmation">
                                { TypeRemoval() }
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content content_confirmation">
                                <div onClick={ ()=>{ TypeAlt(); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
