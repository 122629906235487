import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgAddNewData, SvgDelete, SvgEdit, SvgImg, SvgLockClose, SvgLockOpen, SvgReturn, SvgSave, SvgSetaDown, SvgSetaRight, SvgSetaUp, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Reg_AltPosition, Reg_EditOrRegisterCourses } from "services/RegisterData";

export default function Page_Courses_Details(){

    const [ loading, setLoading ]     = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));
    const [ dataPage, setDataPage ]   = useState(GetDataPage('courses'));
    const [ optSelect, setOptSelect ] = useState(GetDataPage('list_opt_select'));
    const [ idPage, setIdPage ]       = useState(GetListPag('currentPageId'));

    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ]         = useState(InitialData('video'));

    const [ file, setFile ]             = useState(InitialData('file'));
    const [ uploadFile, setUploadFile ] = useState(false);
    const [ img, setImg ]               = useState(InitialData('img'));
    const [ uploadImg, setUploadImg ]   = useState(false);

    const [ highlight, setHighlight ] = useState(InitialData('highlight'))
    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ subtitle, setSubtitle ]   = useState(InitialData('subtitle'));

    const [ free, setFree ]     = useState(InitialData('free'));
    const [ bronze, setBronze ] = useState(InitialData('bronze'));
    const [ prata, setPrata ]   = useState(InitialData('prata'));
    const [ gold, setGold ]     = useState(InitialData('gold'));

    const [ category, setCategory ]       = useState(InitialData('category'));
    const [ text, setText ]               = useState(InitialData('text'));
    const [ observation, setObservation ] = useState(InitialData('observation'));
    const [ listModule, setListModule ]   = useState(ListModule());
    const [ listClasse, setListClasse ]   = useState(InitialData('list_classes'));
    
    const [ listCategory, setListCategory ] = useState(ListCategory());

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('courses', setDataPage);
        RegisterDataPage('list_opt_select', setOptSelect);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setOptSelect(GetDataPage('list_opt_select'));
        setIdPage(GetListPag('currentPageId'));
        setListModule(ListModule());

        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFile(InitialData('file'));
        setImg(InitialData('img'));
        setHighlight(InitialData('highlight'))
        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setFree(InitialData('free'));
        setBronze(InitialData('bronze'));
        setPrata(InitialData('prata'));
        setGold(InitialData('gold'));
        setCategory(InitialData('category'));
        setText(InitialData('text'));
        setObservation(InitialData('observation'));
        setListClasse(InitialData('list_classes'));

        setListCategory(ListCategory());

        setUploadFile(false);
        setUploadImg(false);
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                if(type == 'type_video'){
                    return { "value": newData[type], "label": newData[type] };
                }
                return newData[type];
            }
        }
        if(type == 'type_video'){
            return { "value": "Vimeo", "label": "Vimeo" };
        }

        if(type == 'free' || type == 'bronze' || type == 'prata' || type == 'gold' || type == 'highlight'){
            return false;
        }

        if(type == 'category'){
            return [];
        }

        if(type == 'list_classes'){
            return [];
        }
        return '';
    }

    function ListModule(){
        const newList = [];
        if(idPage !=0){
            optSelect.map((key, index)=>{
                if(key.type ==1){
                    if(key.id_courses === parseInt(idPage)){
                        newList.push(key);
                    }
                }
            });
        }
        const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
        return duplicate;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'courses');
        SetListPag('currentPageId', 'remuve');
        SetListPag('currentPageIdSegund', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(type, value){
        switch (type) {
            case 'img':
                    setFile(value);
                    if(value){
                        setUploadFile(true);
                    }else {
                        setUploadFile(false);
                    }
                break;

            case 'banner':
                    setImg(value);
                    if(value){
                        setUploadImg(true);
                    }else {
                        setUploadImg(false);
                    }
                break;
        }
    }

    function AddOptCategory(){
        setCategory([...category, { "id": 0, "name": "", "newCategory": "" }]);
    }

    function ListCategory(){
        const options = [];
        optSelect.map((key, index)=>{
            if(key.type == '0'){
                options.push({ "value": key.id, "label": key.name });
            }
        })
        const duplicate = options.filter((item, index) => options.indexOf(item) === index);
        return duplicate;
    }

    function HandleCategory(type, index, nameCategory){
        const newData        = [...category];
        newData[index][type] = nameCategory;
        setCategory(newData);
    }

    function DeleteCategory(index, id, value){
        if(id == 0){
            const newData = [...category];
            newData.splice(index, 1);
            setCategory(newData);
        }else {
            SetModalData('Confirmation', { "origin": "courses", "type" : "delete_category", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "courses_details", "type" : "delete_classe", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function ChangePosition(typeOrder, id, order, type){
        Reg_AltPosition(typeOrder, id, order, type, ()=>{ }, ()=>{ CallbackError() });
    }

    function SaveData(event){
        setLoading(true);
        event.preventDefault();
        Reg_EditOrRegisterCourses(idPage, typeVideo.value, video, file, img, highlight, title, subtitle, free, bronze, prata, gold, category, text, observation, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function PageClick(id){
        SetListPag('currentPage', 'courses_details_classes');
        SetListPag('currentPageIdSegund', id);
    }

    return(
        <div className="Page_Courses_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="div_high">
                    Quem pode acessar                            
                    <div className={ free == true ? "high high_active" : "high" } onClick={ ()=>{ setFree(!free) } }>
                        Free
                    </div>
                    <div className={ bronze == true ? "high high_active" : "high" } onClick={ ()=>{ setBronze(!bronze) } }>
                        Bronze
                    </div>
                    <div className={ prata == true ? "high high_active" : "high" } onClick={ ()=>{ setPrata(!prata) } }>
                        Prata
                    </div>
                    <div className={ gold == true ? "high high_active" : "high" } onClick={ ()=>{ setGold(!gold) } }>
                        Ouro
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados do curso</div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <Select className="select_video" defaultOptions options={[{ "value": "Vimeo", "label": "Vimeo" }, { "value": "Youtube", "label": "Youtube" }]} onChange={ (e)=> { setTypeVideo(e); } } value={ typeVideo } placeholder="..." />
                            <span className="name_input">Tipo</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="input_code" onChange={ (e)=>{ setVideo(e.target.value); } } value={ video.replaceAll(' ', '') } maxLength="18" required />
                            <span className="name_input">Código</span>
                        </div>
                        {
                            video !='' ?
                            <div className="div_input" onClick={ ()=>{ OpenFile(typeVideo.value, video) } }>
                                {
                                    typeVideo.value == 'Vimeo' ?
                                    <SvgVimeo color="#ffffff" className="icons"/> :
                                    <SvgYoutube color="#ffffff" className="icons"/>
                                }
                            </div> : ""
                        }
                        <div className="div_show_img">
                            <div className={ uploadFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { uploadFile == true ? "Foto adicionada" : "Alterar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile('img', e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ setFile(''); setUploadFile(false); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        uploadFile == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#FFFFFF" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "courses/img/" + file) } }>
                                            <SvgImg color="#FFFFFF" className="icons"/>
                                        </div>
                                    }
                                </> : null
                            }  
                        </div> 
                        <div className="div_show_img">
                            <div className={ uploadImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { uploadImg == true ? "Banner adicionada" : "Alterar banner" }
                                    <input type="file" onChange={ (e)=>{ AddFile('banner', e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                img !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ setFile(''); setUploadImg(false); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        uploadImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(img)) } }>
                                            <SvgImg color="#FFFFFF" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "courses/img/" + img) } }>
                                            <SvgImg color="#FFFFFF" className="icons"/>
                                        </div>
                                    }
                                </> : null
                            }  
                        </div>
                        <div className="div_switch">
                            <label>Destacar</label>
                            <label className="switch">
                                <div className="switch_wrapper">
                                    <input type="checkbox" value="true" onChange={ ()=>{ setHighlight(!highlight) } } checked={ highlight } />
                                    <span className="switch_button"></span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setSubtitle(e.target.value) } } value={ subtitle } maxLength="80" required />
                            <span className="name_input">Título</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="40" required />
                            <span className="name_input">Título em destaque</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ text != '' ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                            <span className="name_input">Descrição</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_show_data_specific">
                            <div className="type_title">
                                <div className="">Categoria</div>
                                <div className="cursor_pointer" onClick={ ()=>{ AddOptCategory(); } }>
                                    + categoria
                                </div>
                            </div>
                            <div className="div_list_mult_data">
                                {
                                    category.length > 0 ?
                                        category.map((key, index)=>{
                                            console.log(key);
                                            return(
                                                <div className="page_released" key={ index }>
                                                    <div className="div_show_select">
                                                        <select className="select_type" onChange={ (e)=> { HandleCategory('id', index, e.value); HandleCategory('name', index, e.target.value); } } value={ key.id }>
                                                            <option value="0">+ Categoria</option>
                                                            {
                                                                listCategory.map((key_1, index_2)=>{
                                                                    return(
                                                                        <option className="option" value={ key_1.value } key={ index_2 }>{ key_1.label }</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {/* <Select className="select_type" defaultOptions options={ ListCategory() } onChange={ (e)=> { HandleCategory('id', index, e.value); HandleCategory('name', index, e.label); } } value={ { "value": key.id, "label": key.id == 0 ? "+ Categoria" : key.name } }  placeholder="..." /> */}
                                                        {
                                                            key.id != 0 ? null :                         
                                                            <div className="">
                                                                <input type="text" className="input_new_module" onChange={ (e)=>{ HandleCategory('newCategory', index, e.target.value); } } maxLength="80" required placeholder="nome..." />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="" onClick={ ()=>{ DeleteCategory(index, key.id, key.name) } }>
                                                        <SvgDelete color="#FFFFFF" className="icons" />
                                                    </div>
                                                </div>   
                                            )
                                        })
                                    : <div className="no_data">Nenhuma categoria adicionada até o momento...</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Observação fixa</div>
                        <div className="text_span">
                            *fixa em todos os cursos*
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ observation !='' ? observation.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObservation(newContent) } />
                        </div>
                    </div>
                </div>
                {
                    idPage === 0 ? null :                
                    <div className="show_page_data">
                        <div className="type_title">
                            <div className="">Aulas</div>
                            <div className="cursor_pointer" onClick={ ()=>{ PageClick(0) } }>
                                Adicionar nova aula
                            </div>
                        </div>
                        <div className="list_input_data">
                            {
                                listModule.length > 0 ?
                                <table width="100%" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <th width="20" align="center">#</th>
                                            <th>Título</th>
                                            <th width="60" align="right"></th>
                                            <th width="60" align="right"></th>
                                        </tr>
                                    </tbody>
                                    {
                                        listModule.map((key, index)=>{
                                            let nameModule = 'Não definido';
                                            if(key.name != ''){
                                                nameModule = key.name;
                                            }

                                            let showClasses = listClasse.filter(item => item.module == key.id);
                                            return(
                                                <tbody key={ index }>
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="div_order_module">
                                                                {
                                                                    index == 0 ?
                                                                    <div className="space_order"></div> :
                                                                    <div className="space_order" onClick={ ()=>{ ChangePosition('modules', key.id, key.order_, 'up') } }>
                                                                        <SvgSetaUp color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                                {
                                                                    (index + 1) == listModule.length ?
                                                                    <div className="space_order"></div> :
                                                                    <div className="space_order" onClick={ ()=>{ ChangePosition('modules', key.id, key.order_, 'down') } }>
                                                                        <SvgSetaDown color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                                <div className="">
                                                                    <span className="title_table">Módulo:</span> { nameModule }
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showClasses.map((key_1, index_1)=>{
                                                            return(
                                                                <tr key={ index_1 }>
                                                                    <td align="center">{ index_1 + 1 }</td>
                                                                    <td>{ key_1.title }</td>
                                                                    <td>
                                                                        <div className="div_opt_alt">
                                                                            {
                                                                                index_1 == 0 ?
                                                                                <div className="space_div"></div> :
                                                                                <div onClick={ ()=>{ ChangePosition('courses_details', key_1.id, key_1.order_, 'up') } }>
                                                                                    <SvgSetaUp color="#324d6b" className="icons"/>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (index_1 + 1) == showClasses.length ?
                                                                                <div className="space_div"></div> :
                                                                                <div onClick={ ()=>{ ChangePosition('courses_details', key_1.id, key_1.order_, 'down') } }>
                                                                                    <SvgSetaDown color="#324d6b" className="icons"/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="div_opt_alt">
                                                                            <div onClick={ ()=>{ OpenFile(key_1.type_video, key_1.video) } }>
                                                                                {
                                                                                    key_1.video !='' ?
                                                                                        key_1.type_video == 'Vimeo' ?
                                                                                        <SvgVimeo color="#324d6b" className="icons"/> :
                                                                                        <SvgYoutube color="#324d6b" className="icons"/>
                                                                                    : ""
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    key_1.free == true ?
                                                                                    <SvgLockOpen color="#324d6b" className="icons lock" /> :
                                                                                    <SvgLockClose color="#324d6b" className="icons lock" />
                                                                                }
                                                                            </div>
                                                                            <div onClick={ ()=>{ DeleteData(key_1.id, key_1.title) } }>
                                                                                <SvgDelete color="#F00000" className="icons"/>
                                                                            </div>
                                                                            <div onClick={ ()=>{ PageClick(key_1.id) } }>
                                                                                <SvgEdit color="#324d6b" className="icons"/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            )
                                        })
                                    }
                                </table> : 
                                <div className="no_data">Nenhuma aula adicionada até o momento...</div>
                            }
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}