import { useState, useEffect } from "react";

import './Contents.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgPdf, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_Contents(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ bgPage, setBgPage ]   = useState(InitialData('bg_color'));
    const [ title_1, setTitle_1 ] = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ] = useState(InitialData('title_2'));
    const [ title_3, setTitle_3 ] = useState(InitialData('title_3'));
    const [ name_1, setName_1 ]   = useState(InitialData('btn_1_name'));
    const [ name_2, setName_2 ]   = useState(InitialData('btn_2_name'));
    const [ text_1, setText_1 ]   = useState(InitialData('text_1'));
    const [ text_2, setText_2 ]   = useState(InitialData('text_2'));
    const [ text_3, setText_3 ]   = useState(InitialData('text_3'));

    const [ file, setFile ]     = useState(InitialData('file'));
    const [ status, setStatus ] = useState(false);

    const [ img, setImg ]             = useState(InitialData('img_example'));
    const [ statusImg, setStatusImg ] = useState(false);

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setBgPage(InitialData('bg_color'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setName_1(InitialData('btn_1_name'));
        setName_2(InitialData('btn_2_name'));
        setText_1(InitialData('text_1'));
        setText_2(InitialData('text_2'));
        setText_3(InitialData('text_3'));
        setFile(InitialData('file'));
        setStatus(false);
        setImg(InitialData('img_example'));
        setStatusImg(false);

        LoadingData();
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": file, "img": img});
    }

    function HandleBgPage(value){
        setBgPage(value);
        props.ReturnData({"bg_page": value, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": file, "img": img});
    }

    function HandleTitle_1(value){
        setTitle_1(value);
        props.ReturnData({"bg_page": bgPage, "title_1": value, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": file, "img": img});
    }

    function HandleTitle_2(value){
        setTitle_2(value);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": value, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": file, "img": img});
    }

    function HandleTitle_3(value){
        setTitle_3(value);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": value, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": file, "img": img});
    }

    function HandleText_1(value){
        setText_1(value);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": value, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": file, "img": img});
    }

    function HandleText_2(value){
        setText_2(value);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": value, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": file, "img": img});
    }

    function HandleText_3(value){
        setText_3(value);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": value, "name_1": name_1, "name_2": name_2, "file": file, "img": img});
    }

    function HandleName_1(value){
        setName_1(value);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": value, "name_2": name_2, "file": file, "img": img});
    }

    function HandleName_2(value){
        setName_2(value);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": value, "file": file, "img": img});
    }

    function HandleFile(value){
        setFile(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": value, "img": img});
    }

    function HandleImg(value){
        setImg(value);
        value ? setStatusImg(true) : setStatusImg(false);
        props.ReturnData({"bg_page": bgPage, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2, "text_3": text_3, "name_1": name_1, "name_2": name_2, "file": file, "img": value});
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelSite_Contents">
            <div className="list_data_page">

                <div className="div_input space_div title_side">
                    <div className="div_show_img">
                        <div className={ statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { statusImg == true ? "Imagem adicionada" : "Adicionar imagem" }
                                <input type="file" onChange={ (e)=>{ HandleImg(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            img !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ setImg(''); setStatus(false); } }>
                                    <SvgDelete color="#FFFFFF" className="icons" />
                                </div>
                                {
                                    statusImg == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(img)) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "contents/img/" + img) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div>
                                }
                            </> : null
                        }  
                    </div>

                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBgPage(e.target.value) } } value={ bgPage } maxLength="140" />
                        <span className="name_input">Cor de fundo</span>
                    </div>
                    <div className="div_input">
                        <div className="div_color" style={ { background: bgPage } } />
                    </div>
                </div>
                        
                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_1(e.target.value) } } value={ title_1 } maxLength="80" />
                    <span className="name_input">Título 1</span>
                </div>
                        
                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_1(newContent) } />
                    <span className="name_input">Texto 1</span>
                </div>
                        
                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_2(e.target.value) } } value={ title_2 } maxLength="80" />
                    <span className="name_input">Título 2</span>
                </div>
                        
                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_2.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_2(newContent) } />
                    <span className="name_input">Texto 2</span>
                </div>
                        
                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_3(e.target.value) } } value={ title_3 } maxLength="80" />
                    <span className="name_input">Título 3</span>
                </div>
                        
                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_3.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_3(newContent) } />
                    <span className="name_input">Texto 3</span>
                </div>

                <div className="div_input space_div title_side">
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleName_1(e.target.value) } } value={ name_1 } maxLength="20" />
                        <span className="name_input">Nome 1</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleName_2(e.target.value) } } value={ name_2 } maxLength="20" />
                        <span className="name_input">Nome 2</span>
                    </div>
                    <div className="div_show_img">
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Arquivo adicionado" : "Adicionar arquivo" }
                                <input type="file" onChange={ (e)=>{ HandleFile(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            file !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ setImg(''); setStatus(false); } }>
                                    <SvgDelete color="#FFFFFF" className="icons" />
                                </div>
                                {
                                    status == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf_local', URL.createObjectURL(file)) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf', "contents/pdf/" + file) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div>
                                }
                            </> : null
                        }  
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        <div className="ModelPages_ExplanationContents" style={ { background: bgPage } }>
                            <div className="show_data">
                                <div className="div_text">
                                    {
                                        title_1 == "" ? null :
                                        <div className="div_title">
                                            <div className="title">{ title_1 }</div>
                                        </div>
                                    }
                                    {
                                        text_1 == "" ? null : 
                                        <div className="text" dangerouslySetInnerHTML={ { __html: text_1 ? text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                    }

                                    {
                                        title_2 == "" ? null : <div className="subtitle">{ title_2 }</div>
                                    }
                                    {
                                        text_2 == "" ? null : 
                                        <div className="text" dangerouslySetInnerHTML={ { __html: text_2 ? text_2.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                    }

                                    {
                                        title_3 == "" ? null : <div className="subtitle">{ title_3 }</div>
                                    }
                                    {
                                        text_3 == "" ? null : 
                                        <div className="text" dangerouslySetInnerHTML={ { __html: text_3 ? text_3.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                    }

                                    {
                                        file == "" ? null :
                                        <div className="file">
                                            <a href={ status == true ? URL.createObjectURL(file) : "./assets/contents/pdf/" + file } className="show_file" target="_blank">
                                                <div className="show_icons">
                                                    <SvgPdf color="#FF5200" className="icon_pdf" />
                                                </div>
                                                <div className="text_pdf">
                                                    <div className="">{ name_1 }</div>
                                                    <div className="">{ name_2 }</div>
                                                </div>
                                            </a>
                                        </div>
                                    }
                                </div>
                                <div className="list_img">
                                    {
                                        statusImg == true ?
                                        <img alt="img" src={ URL.createObjectURL(img) } className="img" /> : 
                                        <img alt="img" src={ "./assets/contents/img/" + img } className="img" />
                                    }
                                </div>
                            </div>
                        </div>

                        {/* <img alt="home_example" src="./assets/bg_contents.jpg" className="img_example" /> */}
                    </div>
                </div>
            </div>
        </div>
    );

}