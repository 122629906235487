import { useState, useEffect } from "react";

import './Balance.css';

import ReactApexChart from "react-apexcharts";

import { connectionPage, monthList } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgReturn } from "components/SvgFile";

import PopUP_BalanceDetails from "components/PopUp/Balance";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Finacial_Balance(){

    const date = new Date();
    const [ showMonth, setShowMonth ]     = useState(date.getMonth() + 1);
    const [ showYear, setShowYear ]       = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);

    const [ balance, setBalance ] = useState(GetDataPage('balance'));

    const [ listEntry, setListEntry ] = useState(InitiaData('entry'));
    const [ listFee, setListFee ]     = useState(InitiaData('fee'));
    const [ listExit, setListExit ]   = useState(InitiaData('exit'));
    
    const [ series, setSeries ]   = useState(ShowCashGrafic(currentYear));
    const [ options, setOptions ] = useState({
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: '',
            align: ''
        },
        colors:['#7c9f06', '#ff001c'],
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        }
    });

    useEffect(()=>{
        RegisterDataPage('balance', setBalance);
    }, []);

    useEffect(()=>{
        setListEntry(InitiaData('entry'));
        setListFee(InitiaData('fee'));
        setListExit(InitiaData('exit'));

        setSeries(ShowCashGrafic(currentYear));
    }, [currentYear]);

    function InitiaData(type){
        if(balance[type]){
            const newData = balance[type].find(item => item.year == currentYear);
            if(newData){
                return newData['month'];
            }
        }
        return [];
    }

    function ShowCashGrafic(value){
        let data_entry = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let data_exit  = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        // list entry         
        const listDataEntry = balance['entry'].find(item => item.year == value); 
        if(listDataEntry){
            {
                listDataEntry['month'].map((key, index)=>{
                    switch (key.month) {
                        case '01': case 1:
                                data_entry[0] = key.cash
                            return "Janeiro";
                        case '02': case 2:
                                data_entry[1] = key.cash
                            return "Fevereiro";
                        case '03': case 3:
                                data_entry[2] = key.cash
                            return "Março";
                        case '04': case 4:
                                data_entry[3] = key.cash
                            return "Abril";
                        case '05': case 5:
                                data_entry[4] = key.cash
                            return "Maio";
                        case '06': case 6:
                                data_entry[5] = key.cash
                            return "Junho";
                        case '07': case 7:
                                data_entry[6] = key.cash
                            return "Julho";
                        case '08': case 8:
                                data_entry[7] = key.cash
                            return "Agosto";
                        case '09': case 9:
                                data_entry[8] = key.cash
                            return "Setembro";
                        case '10': case 10:
                                data_entry[9] = key.cash
                            return "Outubro";
                        case '11': case 11:
                                data_entry[10] = key.cash
                            return "Novembro";
                        case '12': case 12:
                                data_entry[11] = key.cash
                            return "Dezembro";
                    }
                });
            }
        }
        // list exit
        const listDataExit = balance['exit'].find(item => item.year == value);
        if(listDataExit){
            {
                listExit.map((key, index)=>{
                    switch (key.month) {
                        case '01': case 1:
                                data_exit[0] = key.cash
                            return "Janeiro";
                        case '02': case 2:
                                data_exit[1] = key.cash
                            return "Fevereiro";
                        case '03': case 3:
                                data_exit[2] = key.cash
                            return "Março";
                        case '04': case 4:
                                data_exit[3] = key.cash
                            return "Abril";
                        case '05': case 5:
                                data_exit[4] = key.cash
                            return "Maio";
                        case '06': case 6:
                                data_exit[5] = key.cash
                            return "Junho";
                        case '07': case 7:
                                data_exit[6] = key.cash
                            return "Julho";
                        case '08': case 8:
                                data_exit[7] = key.cash
                            return "Agosto";
                        case '09': case 9:
                                data_exit[8] = key.cash
                            return "Setembro";
                        case '10': case 10:
                                data_exit[9] = key.cash
                            return "Outubro";
                        case '11': case 11:
                                data_exit[10] = key.cash
                            return "Novembro";
                        case '12': case 12:
                                data_exit[11] = key.cash
                            return "Dezembro";
                    }
                });
            }
        }
        return [
            {
                name: "Entrada",
                data: data_entry
            },
            {
                name: "Saída",
                data: data_exit
            }
        ];
    }

    function DetailsCash(type, year, month, name, cash){
        if(cash != '0,00'){
            SetModalData('Balance', { "type": type, "year": year, "month": month, "name": name, "cash": cash });
            SetModalState('Balance', true);
        }
    }
    
    return(
        <div className="Finacial_Balance">
            <div className="new_block">
                <div className="" onClick={ ()=>{ setCurrentYear((currentYear - 1)); setSeries(ShowCashGrafic((currentYear - 1))); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="title_page">
                    Balanço de { currentYear }
                </div>
                <div className="" onClick={ ()=>{ setCurrentYear((currentYear + 1)); setSeries(ShowCashGrafic((currentYear + 1)));} }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>

            <div className="list_data_page">
                {
                    currentYear != showYear ?
                    <div className="current_year" onClick={ ()=>{ setCurrentYear(showYear); } }>
                        <div className="show_text_return_year">
                            Voltar para o ano atual
                        </div>
                    </div> : null
                }
                
                <div className="div_grafic">
                    <ReactApexChart options={ options } series={ series } type="line" height={ 350 } />
                </div>

                <div className="div_download">
                    <div className="download">
                        <a href={ connectionPage + "/php/generatePdf.php?acao=Anual/" + currentYear } target="_blank">
                            Gerar PDF anual
                        </a>
                    </div>
                </div>

                <div className="list_month">
                    <div className="show_month_inf">
                        <div className="div_month">Mês</div>
                        <div className="show_cash">Entrada</div>
                        <div className="show_cash">Taxa</div>
                        <div className="show_cash">Saída</div>
                    </div>
                    {
                        monthList.map((key, index)=>{
                            // cash entry
                            let cashEntryBr = '0,00';
                            let cashEntry = listEntry.find(item => item.month == key.month);                            
                            if(cashEntry != null){
                                cashEntryBr = cashEntry['cash_br'];
                            }

                            // cash fee
                            let feeBr = '0,00';
                            let cashFee = listFee.find(item => item.month == key.month);                            
                            if(cashFee != null){
                                feeBr = cashFee['cash_br'];
                            }

                            // cash exit
                            let cashExitBr = '0,00';
                            let cashExit = listExit.find(item => item.month == key.month);                            
                            if(cashExit != null){
                                cashExitBr = cashExit['cash_br'];
                            }
                            return(
                                <div className="show_month_inf" key={ index }>
                                    <div className="div_month">
                                        { key.name }
                                    </div>
                                    <div className="show_cash details" onClick={ ()=>{ DetailsCash('entry', currentYear, key.month, key.name, cashEntryBr) } }>
                                        { cashEntryBr }
                                    </div>
                                    <div className="show_cash details" onClick={ ()=>{ DetailsCash('fee', currentYear, key.month, key.name, feeBr) } }>
                                        { feeBr }
                                    </div>
                                    <div className="show_cash details" onClick={ ()=>{ DetailsCash('exit', currentYear, key.month, key.name, cashExitBr) } }>
                                        { cashExitBr }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <PopUP_BalanceDetails />
        </div>
    )
}