import { useState, useEffect } from "react";

import './Indicators.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Indicators(props){

    const [ accessDash, setAccessDash ] = useState(GetDataPage('access_dash'));    
    const [ courses, setCourses ]       = useState(GetDataPage('courses'));    
    const [ students, setStudents ]     = useState(GetDataPage('students'));    
    const [ team, setTeam ]             = useState(GetDataPage('team'));    
    const [ entry, setEntry ]           = useState(GetDataPage('entry'));    
    const [ exit, setExit ]             = useState(GetDataPage('exit'));

    const [ listData, setListData ] = useState([
        {
            "open": "",
            "title": "Acesso a dashboard",
            "qtd": accessDash.length
        },
        {
            "open": "",
            "title": "Cursos",
            "qtd": courses.length
        },
        {
            "open": "",
            "title": "Alunos",
            "qtd": students.length
        },
        {
            "open": "",
            "title": "Turmas",
            "qtd": team.length
        },
        {
            "open": "",
            "title": "Faturamento - Entrada",
            "qtd": entry.length
        },
        {
            "open": "",
            "title": "Faturamento - Saída",
            "qtd": exit.length
        },
    ]);
    
    useEffect(()=>{
        RegisterDataPage('access_dash', setAccessDash);
        RegisterDataPage('courses', setCourses);
        RegisterDataPage('students', setStudents);
        RegisterDataPage('team', setTeam);
        RegisterDataPage('entry', setEntry);
        RegisterDataPage('exit', setExit);
    }, []);

    return(
        <div className="Indicators">
            <div className="new_block">
                <div className="title_page">Indicadores</div>
            </div>
            
            <div className="list_current_data">
                {
                    listData.map((key, index)=>{
                        return(
                            <div className="show_qtd" key={ index } onClick={ ()=>{ }}>
                                <div className="number">{ key.qtd }</div>
                                <div className="title">{ key.title }</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}