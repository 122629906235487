import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, SetListPag, RegisterListPag } from "interface/Page";

import { SvgDelete, SvgImg, SvgPdf, SvgReturn, SvgSave } from "components/SvgFile";

import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import { Reg_Exit } from "services/RegisterData";

export default function Finacial_Exit_details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('exit'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ title, setTitle ]             = useState(InitialData('title'));
    const [ typeOrigin, setTypeOrigin ]   = useState(InitialData('type_origin'));
    const [ idLogin, setIdLogin ]         = useState(InitialData('id_login'));
    const [ origin, setOrigin ]           = useState(InitialData('origin'));
    const [ newOrigin, setNewOrigin ]     = useState('');
    const [ typePayment, setTypePayment ] = useState(InitialData('type_payment'));
    const [ fee, setFee ]                 = useState(InitialData('fee'));
    const [ codePag, setCodePag ]         = useState(InitialData('code_pagseguro'));
    const [ newPayment, setNewPayment ]   = useState('');
    const [ bank, setBank ]               = useState(InitialData('bank'));
    const [ newBank, setNewBank ]         = useState('');
    const [ agency, setAgency ]           = useState(InitialData('agency'));
    const [ bill, setBill ]               = useState(InitialData('bill'));
    const [ paymentData, setPaymentData ] = useState(InitialData('payment_data'));
    const [ dueDate, setDueDate ]         = useState(InitialData('due_date'));
    const [ status, setStatus ]           = useState(InitialData('status'));
    const [ cash, setCash ]               = useState(InitialData('cash'));
    const [ file, setFile ]               = useState(InitialData('file'));
    const [ upload, setUpload ]           = useState(false);
    const [ note, setNote ]               = useState(InitialData('note'));

    const [ listBank, setListBank ]               = useState(ListBank());
    const [ listOrigin, setListOrigin ]           = useState(ListOrigin());
    const [ listTypePayment, setListTypePayment ] = useState(ListTypePayment());

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('exit', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('exit'));
        setIdPage(GetListPag('currentPageId'));

        setListBank(ListBank());
        setListOrigin(ListOrigin());
        setListTypePayment(ListTypePayment());
        
        setTitle(InitialData('title'));
        setTypeOrigin(InitialData('type_origin'));
        setIdLogin(InitialData('id_login'));
        setOrigin(InitialData('origin'));
        setTypePayment(InitialData('type_payment'));
        setFee(InitialData('fee'))
        setCodePag(InitialData('code_pagseguro'));
        setBank(InitialData('bank'));
        setAgency(InitialData('agency'));
        setBill(InitialData('bill'));
        setPaymentData(InitialData('payment_data'));
        setDueDate(InitialData('due_date'));
        setStatus(InitialData('status'));
        setCash(InitialData('cash'));
        setFile(InitialData('file'));
        setNote(InitialData('note'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('exit'));
        setIdPage(GetListPag('currentPageId'));

        setListBank(ListBank());
        setListOrigin(ListOrigin());
        setListTypePayment(ListTypePayment());
        
        setTitle(InitialData('title'));
        setTypeOrigin(InitialData('type_origin'));
        setIdLogin(InitialData('id_login'));
        setOrigin(InitialData('origin'));
        setTypePayment(InitialData('type_payment'));
        setFee(InitialData('fee'))
        setCodePag(InitialData('code_pagseguro'));
        setBank(InitialData('bank'));
        setAgency(InitialData('agency'));
        setBill(InitialData('bill'));
        setPaymentData(InitialData('payment_data'));
        setDueDate(InitialData('due_date'));
        setStatus(InitialData('status'));
        setCash(InitialData('cash'));
        setFile(InitialData('file'));
        setNote(InitialData('note'));
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'origin'){
                return { "value": newData['origin'], "label": newData['origin'] }
            }
            if(type == 'id_login'){
                return { "value": newData['id_login'], "label": newData['type'] }
            }
            return newData[type];
        }
        if(type == 'type_origin'){
            return 'Outros';
        }
        if(type == 'page'){
            return [];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'exit');
        SetListPag('currentPageId', 'remuve');
    }

    function ListBank(){        
        const newData = [];
        GetDataPage('exit').forEach(item =>{
            if(item.bank !=''){
                newData.push(item.bank);
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function ListOrigin(){        
        const newData = [];
        GetDataPage('exit').forEach(item =>{
            if(item.origin !=''){
                newData.push(item.origin);
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function ListTypePayment(){        
        const newData = [];
        GetDataPage('exit').forEach(item =>{
            if(item.type_payment !=''){
                newData.push(item.type_payment);
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function ShowOrigin(){
        const options = [{ "value": "add", "label": "Adicionar novo" }]; 
        listOrigin.map((key, index)=>{
            options.push({
                "value": key, "label": key
            });
        })
        return options;
    }

    function ShowStudents(){
        const options = [];
        GetDataPage('students').map((key, index)=>{
            let nameStudy = 'Nome: ' + key.name + ' - Email: ' + key.email;
            options.push({
                "value": key.id, "label": nameStudy
            });
        })
        return options;
    }   

    function AddFile(value){
        setFile(value);
        if(value){
            setUpload(true);
        }else {
            setUpload(false);
        }
    }

    function ShowFile(value){
        switch (upload) {
            case true:
                let typeFile = value.type.split('/');
                switch (typeFile[0]) {
                    case "image":
                        return (
                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(value)) } }>
                                <SvgImg color="#324d6b" className="icons"/>
                            </div>
                        )
                                
                    case "application":
                        return (
                            <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf_local', URL.createObjectURL(value)) } }>
                                <SvgPdf color="#324d6b" className="icons"/>
                            </div>
                        )
                }

            case false:
                let dataFile = InitialData('file').split('.');
                switch (dataFile[1]) {
                    case 'pdf':   
                        return (
                            <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf', "financial/entry/" + value) } }>
                                <SvgPdf color="#324d6b" className="icons"/>
                                </div>
                            )  

                    default:
                        return (
                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "financial/entry/" + value) } }>
                                <SvgImg color="#324d6b" className="icons"/>
                            </div>
                        )
                }
                break;
        }
    }

    function OpenFile(type, value){
        SetModalData('ShowFile', { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Exit(idPage, title, status, bank, newBank, agency, bill, dueDate, paymentData, cash, file, typeOrigin, origin.value, newOrigin, idLogin.value, typePayment, newPayment, codePag, fee, note, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setUpload(false);
        setLoading(false);        
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="FinancialEntry_details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados do faturamento de saída</div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>
                        <div className="div_input">
                            <select onChange={ (e)=>{ setStatus(e.target.value) } } value={ status }>
                                <option value="0">Em aberto</option>
                                <option value="1">Pago</option>
                                <option value="2">Cancelado</option>
                            </select>
                            <span className="name_input">Status</span>
                        </div>
                    </div>
                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input">
                            <select className="select_bank" onChange={ (e)=>{ setBank(e.target.value) } } value={ bank }>
                                <option value="">#</option>
                                <option value="add">Adicionar</option>
                                {
                                    listBank.map((key, index)=>{
                                        return (
                                            <option value={ key } key={ index }>{ key }</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Banco</span>
                        </div>
                        {
                            bank == 'add' ?
                            <div className="div_input">
                                <input type="text" className="input_data" onChange={ (e)=>{ setNewBank(e.target.value) } } />
                                <span className="name_input">Nome</span>
                            </div>
                            : null
                        }
                        <div className="div_input">
                            <input type="text" className="input_data" onChange={ (e)=>{ setAgency(e.target.value) } } value={ agency } />
                            <span className="name_input">Agência</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="input_agency" onChange={ (e)=>{ setBill(e.target.value) } } value={ bill } />
                            <span className="name_input">Conta</span>
                        </div>
                        <div className="div_input">
                            <input type="date" className="input_date" onChange={ (e)=>{ setDueDate(e.target.value) } } value={ dueDate } />
                            <span className="name_input">Data do vencimento</span>
                        </div>
                        <div className="div_input">
                            <input type="date" className="input_date" onChange={ (e)=>{ setPaymentData(e.target.value) } } value={ paymentData } />
                            <span className="name_input">Data do pagamento</span>
                        </div>

                        <div className="div_input">
                            <input type="text" className="input_cash" onChange={ (e)=>{ setCash(e.target.value) } } placeholder="0,00" value={ cash.replaceAll(' ', '') } />
                            <span className="name_input">Valor</span>
                        </div>
                    </div>

                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input">
                            <select onChange={ (e)=>{ setTypeOrigin(e.target.value); setOrigin([]); setIdLogin([]); } } value={ typeOrigin }>
                                <option value="Outros">Outros</option>
                                <option value="Alunos">Alunos</option>
                            </select>
                            <span className="name_input">Destino</span>
                        </div>                        
                         
                        {
                            typeOrigin == "Outros" ?
                            <div className="div_input space_div">
                                <Select className="select_search" defaultOptions value={ origin } options={ ShowOrigin() } onChange={ (e)=> { setOrigin(e); } } />
                                <span className="name_input">Lista dos "outros"</span>
                            </div> :
                            <div className="div_input space_div">
                                <Select className="select_search" defaultOptions value={ idLogin } options={ ShowStudents() } onChange={ (e)=> { setIdLogin(e); } } />
                                <span className="name_input">Lista dos alunos</span>
                            </div>
                        }

                        {
                            origin.value == 'add' ?
                            <div className="div_input">
                                <input type="text" className="input_data" onChange={ (e)=>{ setNewOrigin(e.target.value) } } value={ newOrigin } maxLength="20" />
                                <span className="name_input">Nome</span>
                            </div>
                            : null
                        }
                    </div>

                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input">
                            <select className="select_payment" onChange={ (e)=>{ setTypePayment(e.target.value) } } value={ typePayment }>
                                <option value="">#</option>
                                <option value="add">Adicionar</option>
                                {
                                    listTypePayment.map((key, index)=>{
                                        return (
                                            <option value={ key } key={ index }>{ key }</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Tipo pagamento</span>
                        </div>

                        {
                            typePayment == 'add' ?
                            <div className="div_input">
                                <input type="text" className="data_input" onChange={ (e)=>{ setNewPayment(e.target.value) } } maxLength="80" />
                                <span className="name_input">Nome</span>
                            </div>
                            : null
                        } 

                        <div className="div_input">
                            <input type="text" className="input_data" onChange={ (e)=>{ setCodePag(e.target.value) } } value={ codePag } />
                            <span className="name_input">Código</span>
                        </div>

                        <div className="div_input">
                            <input type="text" className="input_fee" onChange={ (e)=>{ setFee(e.target.value) } } value={ fee.replaceAll(' ', '') } />
                            <span className="name_input">Taxa</span>
                        </div>

                        <div className="div_show_img">
                            <div className={ upload == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { upload == true ? "Comprovante adicionado" : "Adicionar comprovante" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ setFile(''); setUpload(false); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        upload == true ? ShowFile(file) : ShowFile(file)
                                    }
                                </> : null
                            }  
                        </div>
                    </div>

                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                            <span className="name_input">Observação</span>
                        </div>
                    </div>
                </div>
            </form>
            <PopUP_ReturnResponse />
        </div>
    )
}