import { useState, useEffect } from "react";

import './PersonalizedService.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgPdf, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_PersonalizedService(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ file, setFile ]       = useState(InitialData('bg_img'));
    const [ bgColor, setBgColor ] = useState(InitialData('bg_color'));
    const [ status, setStatus ]   = useState(false);
    const [ title_1, setTitle_1 ] = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ] = useState(InitialData('title_2'));
    const [ title_3, setTitle_3 ] = useState(InitialData('title_3'));
    const [ title_4, setTitle_4 ] = useState(InitialData('title_4'));
    const [ text_1, setText_1 ]   = useState(InitialData('text_1'));
    const [ text_2, setText_2 ]   = useState(InitialData('text_2'));
    const [ text_3, setText_3 ]   = useState(InitialData('text_3'));

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setFile(InitialData('bg_img'));
        setBgColor(InitialData('bg_color'));
        setStatus(false);
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setTitle_4(InitialData('title_4'));
        setText_1(InitialData('text_1'));
        setText_2(InitialData('text_2'));
        setText_3(InitialData('text_3'));

        LoadingData();
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({"file": file, "bg_color": bgColor, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleFile(value){
        setFile(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({"file": value, "bg_color": bgColor, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleBgColor(value){
        setBgColor(value);
        props.ReturnData({"file": file, "bg_color": value, "title_1": value, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleTitle_1(value){
        setTitle_1(value);
        props.ReturnData({"file": file, "bg_color": bgColor, "title_1": value, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleTitle_2(value){
        setTitle_2(value);
        props.ReturnData({"file": file, "bg_color": bgColor, "title_1": title_1, "title_2": value, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleTitle_3(value){
        setTitle_3(value);
        props.ReturnData({"file": file, "bg_color": bgColor, "title_1": title_1, "title_2": title_2, "title_3": value, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleTitle_4(value){
        setTitle_4(value);
        props.ReturnData({"file": file, "bg_color": bgColor, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": value, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleText_1(value){
        setText_1(value);
        props.ReturnData({"file": file, "bg_color": bgColor, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": value, "text_2": text_2, "text_3": text_3});
    }

    function HandleText_2(value){
        setText_2(value);
        props.ReturnData({"file": file, "bg_color": bgColor, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": value, "text_3": text_3});
    }

    function HandleText_3(value){
        setText_3(value);
        props.ReturnData({"file": file, "bg_color": bgColor, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": value});
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelSite_PersonalizedService">
            <div className="list_data_page">

                <div className="div_input space_div title_side">
                    <div className="div_show_img">
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                <input type="file" onChange={ (e)=>{ HandleFile(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            file !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ HandleFile(''); } }>
                                    <SvgDelete color="#FFFFFF" className="icons" />
                                </div>
                                {
                                    status == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "personalized_service/" + file) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div>
                                }
                            </> : null
                        }  
                    </div>

                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBgColor(e.target.value) } } value={ bgColor } maxLength="140" />
                        <span className="name_input">Cor de fundo</span>
                    </div>
                    <div className="div_input">
                        <div className="div_color" style={ { background: bgColor } } />
                    </div>
                </div>

                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_1(e.target.value) } } value={ title_1 } maxLength="80" />
                    <span className="name_input">Título 1</span>
                </div>

                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_2(e.target.value) } } value={ title_2 } maxLength="80" />
                    <span className="name_input">Título 2</span>
                </div>

                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_1(newContent) } />
                    <span className="name_input">Texto 1</span>
                </div>

                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_3(e.target.value) } } value={ title_3 } maxLength="80" />
                    <span className="name_input">Título 3</span>
                </div>

                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_2.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_2(newContent) } />
                    <span className="name_input">Texto 2</span>
                </div>

                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_4(e.target.value) } } value={ title_4 } maxLength="80" />
                    <span className="name_input">Título 4</span>
                </div>

                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_3.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_3(newContent) } />
                    <span className="name_input">Texto 3</span>
                </div>
                
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        {
                            title_1 == "" || text_1 == "" ? 
                            <img alt="home_example" src="./assets/bg_personalize.jpg" className="img_example" /> :
                            <div class="ModelPages_PersonalizedService" style={ { background: bgColor } }>
                                <div class="show_data">
                                    <div class="div_text">
                                        <div class="title">{ title_1 }</div>
                                        {
                                            title_2 == "" && text_1 == "" ? null : 
                                            <div class="">
                                                {
                                                    title_2 == "" ? null : <div class="subtitle">{ title_2 }</div>
                                                }                                                
                                                {
                                                    text_1 == '' ? null : 
                                                    <div className="text" dangerouslySetInnerHTML={ { __html: text_1 ? text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                }
                                            </div>
                                        }
                                        
                                        {
                                            title_3 == "" && text_2 == "" ? null : 
                                            <div class="">
                                                {
                                                    title_3 == "" ? null : <div class="subtitle">{ title_3 }</div>
                                                }                                                
                                                {
                                                    text_2 == '' ? null : 
                                                    <div className="text" dangerouslySetInnerHTML={ { __html: text_2 ? text_2.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                }
                                            </div>
                                        }
                                        
                                        {
                                            title_4 == "" && text_3 == "" ? null : 
                                            <div class="">
                                                {
                                                    title_4 == "" ? null : <div class="subtitle">{ title_4 }</div>
                                                }                                                
                                                {
                                                    text_3 == '' ? null : 
                                                    <div className="text" dangerouslySetInnerHTML={ { __html: text_3 ? text_3.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class="background_image">
                                    {
                                        status == true ?
                                        <img alt="img" src={ URL.createObjectURL(file) } className="img_home" /> : 
                                        <img alt="img" src={ "./assets/personalized_service/" + file } className="img_home" />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}