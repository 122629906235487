import React, { useState, useEffect } from "react";

import './DataProfile.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgClose, SvgImg } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Reg_NewProfile } from "services/RegisterData";

export default function PopUp_DataProfile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ status, setStatus ] = useState(false);
    const [ file, setFile ]     = useState('');
    const [ name, setName ]     = useState('');
    const [ area, setArea ]     = useState('');
    const [ date, setDate ]     = useState('');
    const [ text, setText ]     = useState('');

    useEffect(()=>{
        RegisterModalData('DataProfile', setModaldata);
        RegisterModalObserver('DataProfile', setShowPopUp);
    }, []);

    function SaveData(){
        Reg_NewProfile(modalData.id, modalData.page, file, name, area, date, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }
    
    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        setStatus(false);
        setFile('');
        setName('');
        setArea('');
        setDate('');
        setText('');
        SetModalState('DataProfile', false);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all DataProfile">
                        <div className="div_data">
                            <div className="title">
                                Registrar novo perfil
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">

                                <div className="list_data_page">
                                    <div className="div_show_img" style={ { marginBottom: "10px" } }>
                                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                <input type="file" onChange={ (e)=>{ setFile(e.target.files[0]); setStatus(true) } } accept="image/*"/>
                                            </label>
                                        </div>
                                        {
                                            file !='' ?
                                                status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                    <SvgImg color="#FFFFFF" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "depositions/" + file) } }>
                                                    <SvgImg color="#FFFFFF" className="icons"/>
                                                </div> : null
                                        }  
                                    </div> 

                                    <div className="div_input space_div title_side">
                                        <div className="div_input show_div_input">
                                            <input type="text" className="input_name" onChange={ (e)=>{ setName(e.target.value); } } maxLength="80" />
                                            <span className="name_input">Nome</span>
                                        </div>
                                        <div className="div_input space_div show_div_input">
                                            <input type="text" className="data_input" onChange={ (e)=>{ setArea(e.target.value); } } maxLength="80" />
                                            <span className="name_input">Área</span>
                                        </div>
                                        <div className="div_input show_div_input">
                                            <input type="text" className="input_date" onChange={ (e)=>{ setDate(e.target.value); } } maxLength="80" />
                                            <span className="name_input">Data</span>
                                        </div>
                                    </div>

                                    <div className="div_input space_div">
                                        <JoditEditor className="show_textarea" config={ config } onBlur={ newContent => setText(newContent) } />
                                        <span className="name_input">Descrição</span>
                                    </div>
                                </div>
                                
                                <button onClick={ ()=>{ SaveData() } } className="new_block_text" style={ { width: "-webkit-fill-available" } }>
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}