import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_Team, Reg_TeamStatus } from "services/RegisterData";

import { SvgAddNewData, SvgDelete, SvgEdit, SvgImg, SvgReturn, SvgSave, SvgSetaRight, SvgStatus } from "components/SvgFile";
import PopUp_DetailsStudy from "components/PopUp/DetailsStudy";
import PopUP_StatusTeam from "components/PopUp/StatusTeam";

export default function Page_Team_Details(){

    const [ loading, setLoading ]         = useState(false);
    const [ userId, setUserId ]           = useState(GetUserData('id'));
    const [ dataPage, setDataPage ]       = useState(GetDataPage('team'));
    const [ students, setStudents ]       = useState(GetDataPage('students'));
    const [ idPage, setIdPage ]           = useState(GetListPag('currentPageId'));

    const [ name, setName ]               = useState(InitialData('name'));
    const [ dateStart, setDateStart ]     = useState(InitialData('date_start'));
    const [ dateEnd, setDateEnd ]         = useState(InitialData('date_end'));
    const [ note, setNote ]               = useState(InitialData('note'));
    const [ course, setCourse ]           = useState(InitialData('id_course'));
    const [ listStudy, setListStudy ]     = useState(InitialData('list_study'));
    const [ status, setStatus ]           = useState(false);
    const [ studentsAdd, setStudentsAdd ] = useState([]);

    const [ listClasse, setListClasse ] = useState(ListClasse());

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('team', setDataPage);
        RegisterDataPage('students', setStudents);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('team'));
        setStudents(GetDataPage('students'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setDateStart(InitialData('date_start'));
        setDateEnd(InitialData('date_end'));
        setNote(InitialData('note'));
        setCourse(InitialData('id_course'));
        setListStudy(InitialData('list_study'));

        setStatus(false);
        setStudentsAdd([]);

        setListClasse(ListClasse());
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'id_course'){
                return { "value": newData['id_course'], "label": newData['name_course'] };
            }
            return newData[type];
        }
        if(type == 'id_course'){
            return { "value": 0, "label": "Seleciona um curso" };
        }
        if(type == 'list_study'){
            return [];
        }
        return '';
    }

    function ListCourse(){
        const options = []; 
        GetDataPage('courses').map((key, index)=>{
            options.push({
                "value": key.id, "label": key.title
            });
        })
        return options;
    }

    function ListClasse(){
        if(course.value == 0){
            return [];
        }else {
            const newData = GetDataPage('courses').find(item => item.id === course.value);
            return newData.list_classes;
        }
    }

    function ListStudents(){
        const options = []; 
        GetDataPage('students').map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'team');
        SetListPag('currentPageId', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AltStatusClasse(id_user, id_classe, id_reg, release, punctuation){
        SetModalData('StatusTeam', { "id_page": idPage, "id_user" : id_user, "id_classe": id_classe, "id_reg": id_reg, "release": release, "punctuation": punctuation });
        SetModalState('StatusTeam', true);
    }

    function TypeStatus(value){
        switch (value) {
            case 0:
                break;

            case 1:
                return <SvgStatus color="#ffad00" className="icons" />;

            case 2:
                return <SvgStatus color="#7c9f06" className="icons" />;

            case 3:
                return <SvgStatus color="#F00000" className="icons" />;
        }
    }

    function OpenDetailsStudy(id, name){
        SetModalData('StatusClasses', { "id": id, "name": name });
        SetModalState('StatusClasses', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "team", "type" : "delete_user", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function OpenEditClasses(id_user, id, status, status_classe, text_classe, listFile){
        SetModalData('StatusTeam', { "id_page": idPage, "id_user" : id_user, "id": id, "status": status, "status_classe": status_classe, "text_classe": text_classe, "file_classe": listFile });
        SetModalState('StatusTeam', true);
    }

    function HandleClasse(index, index_1, value){
        const newData = [...listStudy];
        newData[index].state_classes[index_1]['status'] = value;
        setListStudy(newData); 
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        if(status == false){
            setStudentsAdd([]);
        }
        Reg_Team(idPage, name, dateStart, dateEnd, note, course.value, listStudy, studentsAdd, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
    
    return(
        <div className="Page_Team_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>
                <div className="show_page_data">
                    <div className="type_title">Dados da turma</div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            {
                                idPage == 0 ?
                                <Select className="space_div" defaultOptions value={ course } options={ ListCourse() } onChange={ (e)=> { setCourse(e); } } /> :
                                <input type="text" className="space_div" value={ name } style={ { cursor: "default" } } readOnly />
                            }
                            <div className="name_input">Cursos</div>
                        </div>
                        <div className="div_input">
                            <input type="date" className="" onChange={ (e)=>{ setDateStart(e.target.value) } } value={ dateStart } maxLength="140" />
                            <span className="name_input">Data início</span>
                        </div>
                        <div className="div_input">
                            <input type="date" className="" onChange={ (e)=>{ setDateEnd(e.target.value) } } value={ dateEnd } maxLength="140" />
                            <span className="name_input">Data término</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                            <span className="name_input">Observação</span>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Alunos</div>
                        <div className="cursor_pointer" onClick={ ()=>{ setStatus(!status) } }>
                            { status != true ? "+ aluno" : "- cancelar" }
                        </div>
                    </div>
                    {
                        status != true ? null : 
                        <div className="div_input add_new_course">
                            <div className="div_input space_div">                            
                                <Select className="div_space" defaultOptions options={ ListStudents() } onChange={ (e)=> { setStudentsAdd(e); } } value={ studentsAdd } isMulti /> 
                            </div>
                        </div>
                    }
                    <div className="list_input_data">
                        {
                            listStudy.length > 0 ?
                            <div className="show_data_team">
                                <div className="div_name_study">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <th className="table_height" align="center" width="10">#</th>
                                                <th className="table_height" width="140">Aluno</th>
                                            </tr>
                                            {
                                                listStudy.map((key, index)=>{
                                                    return(
                                                        <tr className="table_end_height" key={ index }>
                                                            <td className="table_height" align="center">{ index + 1 }</td>
                                                            <td className="table_height show_name" style={ { cursor: 'pointer' } } onClick={ ()=>{ OpenDetailsStudy(key.id_user, key.name) } }>
                                                                { key.name }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="div_list_classes">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                {
                                                    listClasse.map((key, index)=>{
                                                        if(key.type == 'upload'){
                                                            return(
                                                                <th className="table_height show_classes_td show_classes_th" key={ index } style={ { cursor: "default" } }>
                                                                    Upload - { index + 1 }
                                                                </th>
                                                            )

                                                        }else {
                                                            return(
                                                                <th className="table_height show_classes_td show_classes_th" key={ index } onClick={ ()=>{ OpenFile(key.type_video, key.video) } }>
                                                                    { index + 1 }
                                                                </th>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tr>                                            
                                            {
                                                listStudy.map((key, index)=>{
                                                    let fileClasse = students.find(item => item.id == key.id_user);
                                                    let showFile   = fileClasse.list_files;

                                                    return(
                                                        <tr key={ index }>
                                                            {
                                                                key.state_classes.map((key_1, index_1)=>{
                                                                    let bgColor = 'transparent';
                                                                    switch (key_1.status_classe) {
                                                                        case 1:
                                                                                bgColor = '#ffad00';
                                                                            break;
                                                                        case 2:
                                                                                bgColor = '#7c9f06';
                                                                            break;
                                                                        case 3:
                                                                                bgColor = '#F00000';
                                                                            break;
                                                                    }
                                                                    let typeClasse = [];
                                                                    if(listClasse.find(item => item.id == key_1.id_classe)){
                                                                        typeClasse = listClasse.find(item => item.id == key_1.id_classe);
                                                                    }

                                                                    let fileAddClasse = [];
                                                                    if(showFile.filter(item => item.id_classe == key_1.id_classe)){
                                                                        fileAddClasse = showFile.filter(item => item.id_classe == key_1.id_classe)
                                                                    }

                                                                    return(
                                                                        <td className="table_height show_classes_td" key={ index_1 } style={ { backgroundColor: bgColor } }>
                                                                            <div className="div_opt_alt" style={ { justifyContent: "center" } }>
                                                                                <div className="">
                                                                                    <input type="checkbox" className="data_classes" value="true" onChange={ (e)=>{ HandleClasse(index, index_1, !key_1.status) } } checked={ key_1.status } />
                                                                                </div>
                                                                                {
                                                                                    typeClasse.type == 'upload' && fileAddClasse.length > 0 ?
                                                                                    <div className="" onClick={ ()=>{ OpenEditClasses(key.id_user, key_1.id, key_1.status, key_1.status_classe, key_1.text, fileAddClasse) } }>
                                                                                        <SvgEdit className="icons" color="#061821" />
                                                                                    </div> : null
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="div_delete">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <th className="table_height" align="center" width="20">#</th>
                                            </tr>
                                            {
                                                listStudy.map((key, index)=>{
                                                    return(
                                                        <tr className="table_end_height" key={ index }>
                                                            <td className="table_height">
                                                                <div className="div_opt_alt">
                                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                                        <SvgDelete color="#F00000" className="icons"/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            status != true ? <div className="no_data">Nenhum curso liberado até o momento...</div> : null
                        }
                    </div>
                </div>
            </form>
            <PopUp_DetailsStudy />
            <PopUP_StatusTeam />
        </div>
    )
}