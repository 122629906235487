
let DataPage = {
    "access_dash"     : [],
    "courses"         : [],
    "list_opt_select" : [],
    "students"        : [],
    "team"            : [],

    "financial_entry"   : [],
    "financial_exit"    : [],
    "financial_balance" : [],

    "team"       : [],

    "home"      : [],
    "how_works" : [],
    "mentoring" : [],
    "rolo"      : [],
    "payment"   : [],
    "category_payment": [],
    "nameMenu"  : [],
    
    "indicators" : []
};

let NotifyDataPage = {
    "access_dash"     : [],
    "courses"         : [],
    "list_opt_select" : [],
    "students"        : [],
    "team"            : [],

    "financial_entry"   : [],
    "financial_exit"    : [],
    "financial_balance" : [],

    "team"       : [],

    "home"      : [],
    "how_works" : [],
    "mentoring" : [],
    "rolo"      : [],
    "payment"   : [],
    "category_payment": [],
    "nameMenu"  : [],
    
    "indicators" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["access_dash"].forEach(element => {       element(value["access_dash"]); });
    NotifyDataPage["courses"].forEach(element => {           element(value["courses"]); });
    NotifyDataPage["list_opt_select"].forEach(element => {   element(value["list_opt_select"]); });
    NotifyDataPage["students"].forEach(element => {          element(value["students"]); });
    NotifyDataPage["team"].forEach(element => {              element(value["team"]); });

    NotifyDataPage["financial_entry"].forEach(element => {   element(value["financial_entry"]); });
    NotifyDataPage["financial_exit"].forEach(element => {    element(value["financial_exit"]); });
    NotifyDataPage["financial_balance"].forEach(element => { element(value["financial_balance"]); });

    NotifyDataPage["home"].forEach(element => {       element(value["home"]); });
    NotifyDataPage["how_works"].forEach(element => {  element(value["how_works"]); });
    NotifyDataPage["mentoring"].forEach(element => {  element(value["mentoring"]); });
    NotifyDataPage["rolo"].forEach(element => {       element(value["rolo"]); });
    NotifyDataPage["payment"].forEach(element => {    element(value["payment"]); });
    NotifyDataPage["category_payment"].forEach(element => {    element(value["category_payment"]); });
    NotifyDataPage["nameMenu"].forEach(element => {   element(value["nameMenu"]); });
    
    NotifyDataPage["indicators"].forEach(element => { element(value["indicators"]); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
