import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePageId, typeCookieSegundId } from "dataFixed";

import Login from "templates/Login";
import Contents from "templates/Contents";

import Access from "services/AccessDash";

export default function App() {

    const cookies = new Cookies();
    const [ typePage, setTypePage ]       = useState(GetListPag('page'));

    useEffect(()=>{
        RegisterListPag('page', setTypePage);

        if(cookies.get(typeCookie)){
            let email = cookies.get(typeCookieEmail);
            let passw = cookies.get(typeCookiePassw);
            Access('return', email, passw, CallbackSuccess, CallbackError);
        }

        if(cookies.get(typeCookiePageId)){
            let idPage = cookies.get(typeCookiePageId);
            if(idPage == 'undefined'){
                SetListPag('currentPage', 'index');
                SetListPag('currentPageId', 'remuve');
            }else {
                SetListPag('currentPageId', idPage);
                let idPageSegund = cookies.get(typeCookieSegundId);
                if(idPageSegund >= 0){
                    SetListPag('currentPageIdSegund', idPageSegund);
                }else {
                    SetListPag('currentPageIdSegund', 'remuve');
                }
            }
        }
    }, []);

    function CallbackSuccess(){
    }

    function CallbackError(){     
    }

    // checked page
    function CheckedPage(){
        switch(typePage){
            case "dashboard":
                return <Contents />;

            default:
                return <Login />;
        }
    }

    return CheckedPage();
}