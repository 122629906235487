import { useState, useEffect } from "react";

import './Rolo.css';

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSearch, SvgSetaUp, SvgSetaDown } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Reg_AltPosition } from "services/RegisterData";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Site_Rolo(){

    const [ loading, setLoading ]           = useState(false);
    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('rolo'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('rolo', setShowPageData);
    }, []);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('rolo').forEach(item =>{
                if(item.type.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.title_1.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.title_2.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.title_3.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.title_4.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.title_5.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.title_6.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.btn_1_name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.btn_2_name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text_1.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text_2.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text_3.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('rolo'));
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "rolo", "type" : "delete_data", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function ChangePosition(id, order, type){
        Reg_AltPosition('rolo', id, order, type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'rolo_details')
        SetListPag('currentPageId', idPage);
    }

    return(
        <div className="Site_Rolo">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Rolo</div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <SvgAddNewData color="#ffffff" className="icons" />
                    bloco
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th width="60">Ordem</th>
                            <th width="60" align="right"></th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            showPageData.map((key, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ index + 1 }</td>
                                        <td>{ key.type }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    index == 0 ?
                                                    <div className="space_div_icon"></div> :
                                                    <div data-tooltip-id={ "position_" + key.id } data-tooltip-content="Alterar posição" data-tooltip-place="top" onClick={ ()=>{ ChangePosition(key.id, key.order_, 'up') } }>
                                                        <SvgSetaUp color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                                {
                                                    (index + 1) == showPageData.length ?
                                                    <div className="space_div_icon"></div> :
                                                    <div data-tooltip-id={ "position_" + key.id } data-tooltip-content="Alterar posição" data-tooltip-place="top" onClick={ ()=>{ ChangePosition(key.id, key.order_, 'down') } }>
                                                        <SvgSetaDown color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                                <Tooltip id={ "position_" + key.id } />
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id={ "del_" + key.id } data-tooltip-content="Apagar página" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.type) } }>
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "del_" + key.id } />

                                                <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar página" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edit_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}