import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage,  } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgReturn, SvgSave } from "components/SvgFile";

import { Reg_DataSite } from "services/RegisterData";

import { listTypePages } from "dataFixed";

import ModelSite_Home from "../../ModelPage/Home";
import ModelSite_Video from "../../ModelPage/Video";
import ModelSite_Profile from "../../ModelPage/Profile";
import ModelSite_Contents from "../../ModelPage/Contents";
import ModelSite_Depositions from "../../ModelPage/Depositions";
import ModelSite_StudentWorks from "../../ModelPage/StudentWorks";
import ModelSite_ModulesWorkload from "../../ModelPage/ModulesWorkload";
import ModelSite_PersonalizedService from "../../ModelPage/PersonalizedService";

export default function Site_Rolo_details(){

    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('rolo'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ type, setType ]         = useState(InitialData('type'));
    const [ contents, setContents ] = useState(InitialData('contents'));

    useEffect(()=>{
        RegisterDataPage('rolo', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        ShowNewData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('rolo'));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData('type'));
        ShowNewData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('rolo'));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData('type'));
        ShowNewData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(idPage > 0){
                const newData = dataPage.find(item => item.id == idPage);
                if(type == 'type'){
                    return { "value": newData[type], "label": newData[type] };
                }else if(type == 'contents'){
                    return newData;
                }
            }else {
                SetListPag('currentPageId', 0);
            }
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'rolo');
        SetListPag('currentPageId', 'remuve');
    }

    function ReturnData(value){
        setContents(value);
    }

    function ShowNewData(){
        switch(type.value){
            case "Home":
                return <ModelSite_Home origin="rolo" ReturnData={ ReturnData } />;

            case "Apresentação com vídeo":
                return <ModelSite_Video origin="rolo" ReturnData={ ReturnData } />;

            case "Trabalhos de alunos":
                return <ModelSite_StudentWorks origin="rolo" ReturnData={ ReturnData } />;

            case "Módulos e carga horária":
                return <ModelSite_ModulesWorkload origin="rolo" ReturnData={ ReturnData } />;

            case "Explicação dos conteúdos":
                return <ModelSite_Contents origin="rolo" ReturnData={ ReturnData } />;

            case "Depoimentos":
                return <ModelSite_Depositions origin="rolo" ReturnData={ ReturnData } />;

            case "Atendimento personalizado":
                return <ModelSite_PersonalizedService origin="rolo" ReturnData={ ReturnData } />;

            case "Perfil do professor":
                return <ModelSite_Profile origin="rolo" ReturnData={ ReturnData } />;
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_DataSite(idPage, 'rolo', 'rolo', type.value, contents, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Site_Rolo_details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text" onClick={ ()=>{  } }>
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="type_title">
                        Dados da página
                        {
                            type !='' ? " - " + type.value : null
                        }
                    </div>
                    {
                        idPage !=0 ? null :
                        <div className="list_input_data" style={ { marginBottom: "20px" } }>
                            <div className="div_input space_div">                                
                                <Select className="space_div" defaultOptions options={ listTypePages } onChange={ (e)=> { setType(e); } } required />
                                <div className="name_input">Tipo de página</div>
                            </div>
                        </div>
                    }
                    { ShowNewData() }
                </div>
            </form>
        </div>
    )
}