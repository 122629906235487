import { useState, useEffect } from "react";

import './Classes.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgDelete, SvgGif, SvgImg, SvgPdf, SvgReturn, SvgSave, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Reg_EditOrRegisterClasses } from "services/RegisterData";


export default function Page_Courses_Details_Classes(){
    
    const [ loading, setLoading ]     = useState(false);
    const [ dataPage, setDataPage ]   = useState(GetDataPage('courses'));
    const [ optSelect, setOptSelect ] = useState(GetDataPage('list_opt_select'));
    const [ idCourse, setIdCourse ]   = useState(GetListPag('currentPageId'));
    const [ idPage, setIdPage ]       = useState(GetListPag('currentPageIdSegund'));

    const [ listModule, setListModule ] = useState([]);

    const [ type, setType ]               = useState(InitialData('type'));
    const [ typeVideo, setTypeVideo ]     = useState(InitialData('type_video'));
    const [ video, setVideo ]             = useState(InitialData('video'));
    const [ free, setFree ]               = useState(InitialData('free'));
    const [ module, setModule ]           = useState(InitialData('module'));
    const [ newModule, setNewModule ]     = useState('');
    const [ title, setTitle ]             = useState(InitialData('title'));
    const [ download, setDownload ]       = useState(InitialData('downloads'));
    const [ tips, setTips ]               = useState(InitialData('tips'));
    const [ link, setLink ]               = useState(InitialData('link'));
    const [ observation, setObservation ] = useState(InitialData('observation'));

    useEffect(()=>{
        RegisterDataPage('courses', setDataPage);
        RegisterDataPage('list_opt_select', setOptSelect);
        RegisterListPag('currentPageId', setIdCourse);
        RegisterListPag('currentPageIdSegund', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setOptSelect(GetDataPage('list_opt_select'));
        setIdCourse(GetListPag('currentPageId'));
        setIdPage(GetListPag('currentPageIdSegund'));

        setType(InitialData('type'));
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFree(InitialData('free'));
        setModule(InitialData('module'));
        setNewModule('');
        setTitle(InitialData('title'));
        setDownload(InitialData('downloads'));
        setTips(InitialData('tips'));
        setLink(InitialData('link'));
        setObservation(InitialData('observation'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setOptSelect(GetDataPage('list_opt_select'));
        setIdCourse(GetListPag('currentPageId'));
        setIdPage(GetListPag('currentPageIdSegund'));

        setType(InitialData('type'));
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFree(InitialData('free'));
        setModule(InitialData('module'));
        setNewModule('');
        setTitle(InitialData('title'));
        setDownload(InitialData('downloads'));
        setTips(InitialData('tips'));
        setLink(InitialData('link'));
        setObservation(InitialData('observation'));
    }, [idPage]);
    
    function InitialData(type){
        if(idPage !=0){
            const showCourse = dataPage.find(item => item.id == idCourse);
            const newData    = showCourse.list_classes.find(item => item.id == idPage);
            if(type == 'module'){
                return { "value": newData['module'], "label": newData['name_module'] };
            }
            if(type == 'type_video'){
                return { "value" : newData[type], "label": newData[type] };
            }
            if(type == 'type'){
                if(newData['type'] == 'classe'){
                    return { "value" : newData['type'], "label": "Aula" };
                }else {
                    return { "value" : newData['type'], "label": "Upload" };
                }
            }
            return newData[type];
        }
        if(type == 'downloads'){
            return [];
        }
        
        if(type == 'free'){
            return false;
        }
        
        if(type == 'type_video'){
            return { "value" : "Vimeo", "label": "Vimeo" };;
        }
        return '';
    }

    function ShowModules(){
        const options = [{ "value": "new_module", "label": "+ módulo" }];
        optSelect.map((key, index)=>{
            if(key.type == '1'){
                options.push({ "value": key.id, "label": key.name });
            }
        })
        const duplicate = options.filter((item, index) => options.indexOf(item) === index);
        return duplicate;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'courses_details');
        SetListPag('currentPageIdSegund', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(type, value, index){
        const newData = [...download];
        switch(type){
            case "remuve_img":
                    newData[index].img        = '';
                    newData[index].update_img = false;
                    setDownload(newData);
                break;

            case "remuve_others":
                    newData[index].file        = '';
                    newData[index].update_file = false;
                    setDownload(newData);
                break;

            case "file":
                    newData[index].file        = value;
                    newData[index].update_file = true;
                    setDownload(newData);
                break;

            case "img":
                    newData[index].img       = value.file;
                    newData[index].positionX = value.positionX;
                    newData[index].positionY = value.positionY;
                    newData[index].width     = value.width;
                    newData[index].height    = value.height;
                    newData[index].showWidth = value.showWidth;
                    newData[index].widthReal = value.widthReal;
                    newData[index].update_img = true;
                    setDownload(newData);
                break;
        }
    }

    function AddOptDownload(){
        setDownload([...download, { "id": 0, "file": "", "update_file": false, "title": "", "img": "", "update_img": false, "positionX": "", "positionY": "", "width": "", "height": "", "showWidth": "", "widthReal": "" }])
    }    

    function HandleDataDownload(index, value){
        const newData = [...download];
        newData[index].title = value;
        setDownload(newData);
    }

    function DeleteDownload(index, id, value){
        if(id == 0){
            const newData = [...download];
            newData.splice(index, 1);
            setDownload(newData);
        }else {
            SetModalData("Confirmation", { "origin": "classes", "type" : "delete_classes_download", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function PopUpCrop(index, value){
        const newData = [...download];
        SetModalData('PhotoCrop', { "index": index, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": AddFile });
        SetModalState('PhotoCrop', true);
    }

    function SaveData(event){
        setLoading(true);
        event.preventDefault();
        Reg_EditOrRegisterClasses(idPage, idCourse, type.value, typeVideo.value, video, free, module.value, newModule, title, download, tips, link, observation, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Courses_Details_Classes">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <div className={ free == true ? "free free_active" : "free" } onClick={ ()=>{ setFree(!free) } }>
                            Free{ free == true ? " Ativo" : " Desativado" }
                        </div>
                        <button className="new_block_text" onClick={ ()=>{  } }>
                            <SvgSave color="#ffffff" className="icons" />
                            Salvar
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados da Aula</div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <Select className="select_video" defaultOptions options={[{ "value": "classe", "label": "Aula" }, { "value": "upload", "label": "Upload" }]} onChange={ (e)=> { setType(e); } } value={ type } placeholder="..." required />
                            <span className="name_input">Tipo</span>
                        </div>
                        {
                            type.value == "upload" ? null : 
                            <>                            
                                <div className="div_input">
                                    <Select className="select_video" defaultOptions options={[{ "value": "Vimeo", "label": "Vimeo" }, { "value": "Youtube", "label": "Youtube" }]} onChange={ (e)=> { setTypeVideo(e); } } value={ typeVideo } required placeholder="..." />
                                    <span className="name_input">Tipo Vídeo</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="input_code" onChange={ (e)=>{ setVideo(e.target.value); } } value={ video.replaceAll(' ', '') } maxLength="18" required />
                                    <span className="name_input">Código</span>
                                </div>
                                {
                                    video !='' ?
                                    <div className="div_input" onClick={ ()=>{ OpenFile(typeVideo.value, video) } }>
                                        {
                                            typeVideo.value == 'Vimeo' ?
                                            <SvgVimeo color="#ffffff" className="icons"/> :
                                            <SvgYoutube color="#ffffff" className="icons"/>
                                        }
                                    </div> : ""
                                }
                            </>
                        }
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="80" required />
                            <span className="name_input">Título</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_module" defaultOptions options={ ShowModules() } onChange={ (e)=> { setModule(e); } } value={ module } placeholder="..." required />
                            <span className="name_input">Módulo</span>
                        </div>
                        {
                            module.value == "new_module" ?                     
                            <div className="div_input">
                                <input type="text" className="" onChange={ (e)=>{ setNewModule(e.target.value); } } maxLength="80" required />
                                <span className="name_input" maxLength="14">Nome do módulo</span>
                            </div>  
                            : null
                        }
                    </div>                    
                    
                    <div className="list_input_data">
                        <div className="div_show_data_specific">
                            <div className="type_title add_new_data_array">
                                <div className="">Downloads</div>
                                <div className="cursor_pointer" onClick={ ()=>{ AddOptDownload(); } }>
                                    + Downloads
                                </div>
                            </div>
                            <div className="div_list_mult_data">
                                {
                                    download.length > 0 ?
                                        download.map((key, index)=>{
                                            let iconsFile = '';
                                            if(key.update_file == false){
                                                let ext = key.file.split('.');
                                                switch (ext[1]) {
                                                    case "png": case "jpg": case "jpge":
                                                            iconsFile = <SvgImg color="#324d6b" className="icons"/>;
                                                        break;
                                                    
                                                    case "gif":
                                                            iconsFile = <SvgGif color="#324d6b" className="icons"/>;
                                                        break;
                                                
                                                    case "pdf":
                                                            iconsFile = <SvgPdf color="#324d6b" className="icons"/>;
                                                        break;
                                                    
                                                    default:
                                                            iconsFile = <div className="others">?</div>;
                                                        break;
                                                }
                                            }
                                            return(
                                                <div className="page_released" key={ index }>
                                                    <div className="div_input space_div">
                                                        <input type="text" className="data_input" onChange={ (e)=>{ HandleDataDownload(index, e.target.value) } } value={ key.title } maxLength="140" required />
                                                        <span className="name_input">Título</span>
                                                    </div>
                                                    <div className="div_show_img">
                                                        <div className={ key.update_img == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.update_img == true ? "Imagem adicionada" : "Alterar imagem" }
                                                                <input type="file" onChange={ (e)=>{ PopUpCrop(index, e.target.files[0]); } } accept="image/*"/>
                                                            </label>
                                                        </div>
                                                        {
                                                            key.img !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ AddFile('remuve_img', '', index); } }>
                                                                    <SvgDelete color="#FFFFFF" className="icons" />
                                                                </div>
                                                                {
                                                                    key.update_img == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.img)) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "courses/img/" + key.img) } }>
                                                                        <SvgImg color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                            </> : null
                                                        }  
                                                    </div> 
                                                    <div className="div_show_img">
                                                        <div className={ key.update_file == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.update_file == true ? "Arquivo adicionado" : "Alterar arquivo" }
                                                                <input type="file" onChange={ (e)=>{ AddFile('file', e.target.files[0], index); } } />
                                                            </label>
                                                        </div>
                                                        {
                                                            key.file !='' ?
                                                            <>
                                                                <div className="delete_img_add" onClick={ ()=>{ AddFile('remuve_others', '', index); } }>
                                                                    <SvgDelete color="#FFFFFF" className="icons" />
                                                                </div>
                                                                {
                                                                    key.update_file == true ?
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                        { iconsFile }
                                                                    </div> :
                                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "courses/others/" + key.file) } }>
                                                                        { iconsFile }
                                                                    </div>
                                                                }
                                                            </> : null
                                                        }  
                                                    </div> 
                                                    <div className="" onClick={ ()=>{ DeleteDownload(index, key.id, key.title) } }>
                                                        <SvgDelete color="#FFFFFF" className="icons" />
                                                    </div>
                                                </div>   
                                            )
                                        })
                                    : <div className="no_data">Nenhuma opção de download adicionado até o momento...</div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ tips != '' ? tips.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTips(newContent) } />
                            <span className="name_input">Dicas</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ link != '' ? link.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setLink(newContent) } />
                            <span className="name_input">Link</span>
                        </div>
                    </div>
                    {/* 
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ observation != '' ? observation.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObservation(newContent) } />
                            <span className="name_input">Observação</span>
                        </div>
                    </div> 
                    */}
                </div>
            </form>
        </div>
    )
}