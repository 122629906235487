const ModalState = {
    Confirmation   : false,
    ShowFile       : false,
    ReturnResponse : false,
    Balance        : false,
    StatusClasses  : false,
    StatusTeam     : false,
    NameMenu       : false,
    Doubts         : false,
    DataProfile    : false,
    PhotoCrop      : false
};

const ModalData = {
    Confirmation   : {},
    ShowFile       : {},
    ReturnResponse : {},
    Balance        : {},
    StatusClasses  : {},
    StatusTeam     : {},
    NameMenu       : {},
    Doubts         : {},
    DataProfile    : {},
    PhotoCrop      : {}
}

const NotifyModalObserver = {
    Confirmation   : [],
    ShowFile       : [],
    ReturnResponse : [],
    Balance        : [],
    StatusClasses  : [],
    StatusTeam     : [],
    NameMenu       : [],
    Doubts         : [],
    DataProfile    : [],
    PhotoCrop      : []
}

const NotifyModalData = {
    Confirmation   : [],
    ShowFile       : [],
    ReturnResponse : [],
    Balance        : [],
    StatusClasses  : [],
    StatusTeam     : [],
    NameMenu       : [],
    Doubts         : [],
    DataProfile    : [],
    PhotoCrop      : []
}

// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key, value){
    return ModalData[key][value];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}

// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
